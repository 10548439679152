<template>
    <v-card>
        <v-card-title v-if="standalone">
            Radacct
        </v-card-title>
        <v-card-text> 
            <v-row>
                <v-col md="3" sm=12 v-if="standalone">
                    <v-autocomplete
                        :items="pppoeUsers"
                        v-model="selectedUser"
                        required
                        item-text="Username"
                        item-value="Username"
                        label="PPPOE felhasználó"
                    >

                    </v-autocomplete>
                </v-col>
                <v-col md=3 sm=12>
                    <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="fromDate"
                            label="Tól"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="fromDate"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            
                <v-col md=3 sm=12>
                    <v-menu
                        ref="menu1"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="toDate"
                            label="Ig"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="toDate"
                            no-title
                            @input="menu2 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col md=3 sm=12 class="d-flex flex-row-reverse">
                    <v-btn color="primary" @click="refreshRadacct">Frissít</v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers=radacctHeaders
                :items=radacct
                dense
                :footer-props="tableFooter"
            >
                <template v-slot:item.acctinputoctets="{item}">
                    {{ calculateData(item.acctinputoctets)}}
                </template>

                <template v-slot:item.acctoutputoctets="{item}">
                    {{ calculateData(item.acctoutputoctets)}}
                </template>

                <template v-slot:item.sessiontime="{item}">
                    {{ calculateSessionTime(item.acctstarttime, item.acctstoptime)}}
                </template>
            </v-data-table>

            <div class="mr-5">
                Az adott időszakra összes adatforgalom: 
                <v-icon color="green">mdi-download</v-icon>
                <span style="color: green; margin-right: 10px">{{ calculateData(radacctSum.download) }}</span>
                <v-icon color="red">mdi-upload</v-icon>
                <span style="color: red;">{{ calculateData(radacctSum.upload) }}</span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import {API} from '../../api'
import moment from 'moment'
export default {
    name: 'Radacct',
    props: ['standalone', 'pppoeUsers', 'pppoeUser'],
    data: () => ({
        loading: false, 
        fromDate: null,
        toDate: new Date().toISOString().substr(0, 10),
        menu1: false,
        menu2: false,
        selectedUser: null,
        radacctHeaders: [
            {
                text: 'Feltöltés',
                value: 'acctinputoctets'
            },
            {
                text: 'Letöltés',
                value: 'acctoutputoctets'
            },
            {
                text: 'Session start',
                value: 'acctstarttime'
            },
            {
                text: 'Session stop',
                value: 'acctstoptime'
            },
            {
                text: 'Session idő',
                value: 'sessiontime'
            },
            {
                text: 'Kapcsolatbontás oka',
                value: 'acctterminatecause'
            },
            {
                text: 'MAC',
                value: 'callingstationid' 
            },
            {
                text: 'IP',
                value: 'framedipaddress'
            },
            {
                text: 'NAS IP',
                value: 'nasipaddress'
            },
            {
                text: 'NAS port',
                value: 'nasportid'
            }
        ],
        radacct: [],
        tableFooter: {
            'items-per-page-options': [25, 50, 100, -1]
        },
        radacctSum: {
            download: 0,
            upload: 0,
        }
        // acctinputoctets acctoutputoctets	acctstarttime	acctstoptime	acctterminatecause	calledstationid	callingstationid	framedipaddress	nasipaddress	nasportid

    }),
    watch: {
        fromDate () {
            this.dateFormatted = this.date
        },
    },
    mounted: function(){
        this.fromDate = new Date()
        this.fromDate.setDate(this.fromDate.getDate() - 14)
        this.fromDate = this.fromDate.toISOString().substr(0, 10)

        if (this.pppoeUser){
            this.selectedUser = this.pppoeUser
            this.refreshRadacct()
        }
    },
    methods: {
        refreshRadacct: function(){
            
            if (this.fromDate > this.toDate) {
                alert("Rossz dátum range-et adtál meg!")
            }
            else {
                this.loading = true
                API.instance.get(`radius/radacct`, {params: {username: this.selectedUser, from_date: this.fromDate, to_date: this.toDate}})
                    .then((response) => {
                        this.radacct = response.data

                        this.radacctSum.download = 0
                        this.radacctSum.upload = 0
                        this.radacct.forEach(element => {
                            this.radacctSum.download += element.acctoutputoctets
                            this.radacctSum.upload += element.acctinputoctets
                        });
                    })
                    .catch((err) => {this.$store.dispatch('messages/addText', err)})
                    .finally(() => this.loading = false)
            }
        },
        calculateData: function(octets){
            let kb = (octets / 1024).toFixed(3)
            let mb = (kb / 1024).toFixed(3)
            let gb = (mb / 1024).toFixed(3)
            let tb = (gb / 1024).toFixed(3)

            if (kb < 1024){
                return `${kb} KB`
            }
            else if (mb < 1024){
                return `${mb} MB`
            }
            else if (gb < 1024) {
                return `${gb} GB`
            }
            else if (gb >= 1024){
                return `${tb} TB`
            }
        },
        calculateSessionTime: function(sessionStart, sessionStop){
            if (sessionStop) {sessionStop = moment(Date.parse(sessionStop))}
            else  sessionStop = moment(Date.now())
            sessionStart = moment(Date.parse(sessionStart))
        
            let dur = moment.duration(sessionStop.diff(sessionStart))
            let formatStr = ""

            if (dur.get('days') >= 1) {
                formatStr += `${dur.get('days')} nap `
            }
            if (dur.get('hours') >= 1 || dur.get('minutes') >= 1){
                formatStr += `${dur.get('hours')} óra `
            }
            if (dur.get('minutes') >= 1){
                formatStr += `${dur.get('minutes')} perc`
            }

            return formatStr.trim()

        },
    }
}
</script>