var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.standalone)?_c('v-card-title',[_vm._v(" Radacct ")]):_vm._e(),_c('v-card-text',[_c('v-row',[(_vm.standalone)?_c('v-col',{attrs:{"md":"3","sm":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.pppoeUsers,"required":"","item-text":"Username","item-value":"Username","label":"PPPOE felhasználó"},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"3","sm":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tól","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',{attrs:{"md":"3","sm":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ig","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1),_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"md":"3","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.refreshRadacct}},[_vm._v("Frissít")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.radacctHeaders,"items":_vm.radacct,"dense":"","footer-props":_vm.tableFooter},scopedSlots:_vm._u([{key:"item.acctinputoctets",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateData(item.acctinputoctets))+" ")]}},{key:"item.acctoutputoctets",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateData(item.acctoutputoctets))+" ")]}},{key:"item.sessiontime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateSessionTime(item.acctstarttime, item.acctstoptime))+" ")]}}])}),_c('div',{staticClass:"mr-5"},[_vm._v(" Az adott időszakra összes adatforgalom: "),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-download")]),_c('span',{staticStyle:{"color":"green","margin-right":"10px"}},[_vm._v(_vm._s(_vm.calculateData(_vm.radacctSum.download)))]),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-upload")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.calculateData(_vm.radacctSum.upload)))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }